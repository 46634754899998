import React, { useContext } from "react"
import { GlobalContext } from "../context/provider.js"
import Popup from "reactjs-popup"
import "./browserMessageModal.css"

const BrowserMessageModal = () => {
  const { state, dispatch } = useContext(GlobalContext)

  return (
    <Popup
      open={state.showBrowserMessageModal}
      onClose={() =>
        dispatch({ type: "TOGGLE_BROWSER_MESSAGE_MODAL", show: false })
      }
    >
      <div className="browserMessage-modal">
        <div
          className="close browserMessage-close"
          role="button"
          tabIndex="0"
          onClick={() =>
            dispatch({ type: "TOGGLE_BROWSER_MESSAGE_MODAL", show: false })
          }
          onKeyDown={() =>
            dispatch({ type: "TOOGLE_BROWSER_MESSAGE_MODAL", show: false })
          }
        >
          &times;
        </div>
        <div className="browserMessage-content">
          Functionality on this website may be limited by your browser. <br />{" "}
          <br /> For the optimal experience we recommend the following browsers:{" "}
          <br /> <br />
          Chrome | Firefox | Safari
          <br />
        </div>
      </div>
    </Popup>
  )
}

export default BrowserMessageModal
