import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles.scss"

const IndexPage = () => (
  <Layout space={true}>
    <SEO title="Home Loan Comparison" />
    <Home />
  </Layout>
)

export default IndexPage
